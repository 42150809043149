











































































































































































































.product-desc {
    height: 150px;
}
.product-imitation {
    min-height: 180px;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    span {
        color: #fff;
        z-index: 100;
    }
    .color-overlay {
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .6;
        position: absolute;
    }
}
.product-price {
    top: -33px;
    &.finished {
        background-color: #007bff;
    }
    &.started {
        background-color: #dc3545;
    }
    &.open {
        background-color: #17a2b8;
    }
    &.soon {
        background-color: #E6A23C;
    }
    &.finished {
        background-color: #67C23A;
    }
    &.closed {
        background-color: #909399;
    }
}
.course-none {
    border-radius: 20px;
    font-size: 16px;
    padding: 6px;
    line-height: 22px;
    height: 33px;
    width: 33px;
    color: #fff;
    display: inline-block;
    text-align: center;
}
